import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import 'pages/tenders/redux/init';
import 'shared/find-loads/redux/init';
import 'pages/manage-invoices/redux/init';
import 'pages/my-loads/redux/init';
import 'pages/my-shipments/redux/init';
import 'shared/offers/redux/init';
import 'pages/load-details/redux/init';

import { Pages } from 'pages/pages';
import { AppRoute } from 'app/routesEnum';
import { PageWrapper } from 'app/../containers/layout/page-wrapper/page-wrapper.component';
import { PrivateRoute } from 'shared/components/route/private-route.component';
import { RequiresInsurance } from 'shared/components/insurance/insurance-required-page.component';
import { Footer } from 'app/../containers/layout/footer/footer.component';
import { MaintenanceWindow } from 'app/../containers/layout/maintenance/maintenance-window.component';
import { DetailPopupWrapper } from 'shared/components/detail-popup/detail-popup.component';
import { SiteSection } from 'shared/components/analytics/site-section.component';
import { UserTracking } from 'app/hooks/analytics/use-user-analytics.hook';
import { LegacyAppRoute } from '../shared/enums/routes.enum';
import { LegacyToBetaRedirect } from 'app/legacy/components/legacy-to-beta.component';
import { ErrorBoundary, PageNotFound } from 'shared/components/error-boundary/error-boundary.component';
import { LazyBoundary } from 'shared/components/lazy/lazy-boundary.component';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsPaymentsUser } from 'pages/payments/hooks/use-is-payments-user.hook';
import { useFindLoadsComponent } from './hooks/use-find-loads';
import { PageContentCardFeed } from '@features/braze/page-content-card-feed';
import LoginRoutes from '@pages/login/routes';
import UnSubscribeRoutes from '@pages/unsubscribe/routes';
import RegistrationRoutes from '@features/registration/routes';
import AccountSettingsRoutes from '@features/account-settings/routes';
import AcceptInvitationRoutes from '@features/invitation/routes';
import OktaRoutes from '@features/okta/routes';
import SetUpUserRoutes from '@features/set-up-user/routes';
import { AuthAction, useAction } from '@features/datadog';
import { useStoredUser } from './hooks/store/use-stored-user.hook';

const PAGE_WRAPPED_ROUTES = [
  AppRoute.MY_LOADS,
  AppRoute.FIND_LOADS_BASE,
  AppRoute.HOME,
  AppRoute.LOGIN,
  AppRoute.TEST,
  AppRoute.REGISTRATION,
  AppRoute.RATES,
  AppRoute.TENDERS,
  AppRoute.TENDERS_DEEP_LINK,
  AppRoute.POST_TRUCKS,
  AppRoute.SPOT_BIDS,
  AppRoute.OFFERS,
  AppRoute.CONTACT_US,
  AppRoute.BIN,
  AppRoute.ACCOUNTS_RECEIVABLE,
  AppRoute.PAYMENTS,
  AppRoute.CLAIMS,
  AppRoute.MANAGE_INVOICES,
  AppRoute.CREATE_INVOICE,
  AppRoute.VIEW_INVOICE_BASE,
  AppRoute.EDIT_INVOICE_BASE,
  AppRoute.PREFERRED_LANES,
  AppRoute.SHORT_REGISTRATION,
  AppRoute.SHORT_REGISTRATION_SUCCESS,
  AppRoute.SHORT_REGISTRATION_ERROR,
  AppRoute.UNSUBSCRIBE,
  AppRoute.UNSUBSCRIBE_REGISTRATION,
  AppRoute.UNSUBSCRIBE_REGISTRATION_ERROR,
  AppRoute.UNSUBSCRIBE_ERROR,
  AppRoute.DASHBOARD,
  AppRoute.MY_SHIPMENTS,
  AppRoute.CARRIER_ADVANTAGE_PROGRAM,
  AppRoute.CARRIER_ADVANTAGE_PROGRAM_DEEP_LINK,
  AppRoute.CARRIER_ADVANTAGE_FAQ,
  AppRoute.CARRIER_ADVANTAGE_FAQ_DEEP_LINK,
  AppRoute.CAP_SCORE_DETAILS,
  AppRoute.CAP_SCORE_DETAILS_DEEP_LINK,
  AppRoute.LOGIN_V2,
  AppRoute.OKTA_FAQ,
  AppRoute.RESOLVE_MODAL_BASE,
  AppRoute.RESOLVE_MODAL,
  AppRoute.FINANCIAL_TERMS_AND_CONDITIONS,
  AppRoute.VERIFY,
  AppRoute.VERIFY_EMAIL,
  AppRoute.LOGIN_OTP,
  AppRoute.OKTA_SIGNIN_REDIRECT,
  AppRoute.UNABLE_TO_SIGNIN_ON_WEB,
  AppRoute.ACCOUNT_SETTINGS,
  AppRoute.INVITATION,
  AppRoute.SET_UP_USER,
];

const FIND_LOADS_ROUTES = [AppRoute.FIND_LOADS, AppRoute.BIN, AppRoute.BIN_DEEP_LINK, AppRoute.PREFERRED_LANES];

const FAQ = [AppRoute.OKTA_FAQ];

{
  /* tslint:disable:jsx-no-lambda */
}
export const AppRoutes = () => {
  const [oktaUIEnabled, setOktaEnabled] = useState(false);
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'));
  const track = useAction();
  const renderFindLoadsComponent = useFindLoadsComponent();
  const { authState, oktaAuth } = useOktaAuth();
  const legacyIsAuthenticated = useSelector(store => store.auth.isAuthenticated);
  const oktaStore = useSelector(store => store.okta);
  const user = useStoredUser();

  const isAuthenticated = oktaStore?.isAuthenticated || authState?.isAuthenticated || legacyIsAuthenticated || false;

  // When they attempt to access the root URL (https://beta.navispherecarrier.com/),
  // logged in users get redirected to /login (which redirects to their default start page)
  // non-logged in users get redirected to /home
  const pathname = useSelector(state => state.router.location.pathname);
  const rootRedirect = useMemo(() => (isAuthenticated ? AppRoute.LOGIN : AppRoute.HOME), [isAuthenticated, AppRoute]);

  const { findLoadsSearch, isOktaEnabled } = useFlags();
  const isPaymentsUser = useIsPaymentsUser();
  const isNorthAmerican = useSelector(state => state.auth.user?.isNorthAmerican());
  const currentLocation: string = useSelector<string>(state => state.router.location.pathname);
  const offersComponent = useCallback(
    () => (!(findLoadsSearch && isNorthAmerican) ? <Pages.Offers /> : <Pages.OffersVer2 />),
    [findLoadsSearch, isNorthAmerican]
  );

  const { myLoadsRedesign } = useFlags();

  const UserAuthActions = {
    [`${AppRoute.LOGIN}`]: true,
    [`${AppRoute.FORGOT_USERNAME}`]: true,
    [`${AppRoute.FORGOT_PASSWORD}`]: true,
    [`${AppRoute.FORGOT_PASSWORD_SUCCESS}`]: true,
    [`${AppRoute.FORGOT_USERNAME_SUCCESS}`]: true,
  };

  useEffect(() => {
    if (oktaUIEnabled) {
      const xBearerAuthorization = oktaTokens?.accessToken?.accessToken;
      if (!xBearerAuthorization) {
        track(AuthAction.CHRUILogout, { message: 'Trying to log user out via Routes.tsx', userId: user?.userId });
        // oktaAuth.tokenManager.clear();
        // dispatch(logOut());
      }
    }
  }, [oktaUIEnabled, oktaTokens]);

  const hideFooter = (): boolean => UserAuthActions[currentLocation] || false;

  const [visible, setVisible] = useState(true);
  const close = useCallback(() => setVisible(false), [visible]);

  return (
    <ErrorBoundary errorType="SITE_LEVEL_ERROR">
      <div id="app-routes-component">
        <LazyBoundary>
          <UserTracking />

          <Switch>
            <Route path={AppRoute.ROOT} exact={true}>
              <Redirect to={rootRedirect} />
            </Route>
            {/* Unauthenticated Routes */}
            {/* TODO: What will ado do with this?
            Redirect legacy routes to BETA routes*/}
            <Route path={'/:culture/Applications/EmailLanding/EmailLogIn.aspx'}>
              <Redirect to={'/Applications/EmailLanding/EmailLogIn.aspx'} />
            </Route>
            <Route path={`(${Object.values(LegacyAppRoute).join('|')})`} component={LegacyToBetaRedirect} />
            <Route path={AppRoute.OKTA_SIGNIN_REDIRECT} component={Pages.OktaSigninRedirect} />
            {/* App Routes and Pages.
              aka, the `...rest` of the pages */}
            <Route path={`(${PAGE_WRAPPED_ROUTES.join('|')})`}>
              <PageWrapper>
                <MaintenanceWindow />
                <Route path={AppRoute.HOME} component={Pages.Home} />
                <Route path={AppRoute.TEST} component={Pages.Test} />
                <Route path={AppRoute.CONTACT_US} component={Pages.ContactUs} />

                <LoginRoutes oktaUIEnabled={oktaUIEnabled} />
                <UnSubscribeRoutes />
                <RegistrationRoutes />
                <AccountSettingsRoutes />
                <AcceptInvitationRoutes />
                <SetUpUserRoutes />
                <OktaRoutes />

                <PrivateRoute path={AppRoute.MY_SHIPMENTS}>
                  <SiteSection name="My Shipments">
                    <Pages.MyShipments />
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.MY_LOADS}>
                  <SiteSection name={myLoadsRedesign ? 'My Loads V2' : 'My Loads'}>
                    <Pages.MyLoads visible={visible} close={close} />
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.RESOLVE_MODAL_BASE}>
                  <SiteSection name="My Loads">
                    <Switch>
                      <PrivateRoute path={AppRoute.RESOLVE_MODAL_BASE} exact>
                        <Redirect to={AppRoute.MY_LOADS} />
                      </PrivateRoute>
                      <PrivateRoute
                        path={AppRoute.RESOLVE_MODAL}
                        render={({ match: { params } }) => <Pages.MyLoads visible={visible} close={close} resolveLoadNumber={params.id} />}
                      />
                    </Switch>
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.POST_TRUCKS}>
                  <SiteSection name="Post Trucks">
                    <RequiresInsurance>
                      <Pages.PostTrucks />
                    </RequiresInsurance>
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.OFFERS}>
                  <SiteSection name="My Offers">
                    <RequiresInsurance>{offersComponent()}</RequiresInsurance>
                  </SiteSection>
                </PrivateRoute>

                <Route path={AppRoute.TENDERS}>
                  <SiteSection name="Tenders">
                    <Switch>
                      <PrivateRoute path={AppRoute.TENDERS_DEEP_LINK} render={({ match: { params } }) => <Pages.Tenders tenderId={params.id} />} />
                      <PrivateRoute path={AppRoute.TENDERS} component={Pages.Tenders} />
                    </Switch>
                  </SiteSection>
                </Route>

                <PrivateRoute path={AppRoute.RATES}>
                  <SiteSection name="Rates">
                    <Pages.Rates />
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.SPOT_BIDS}>
                  <SiteSection name="Spot Bids">
                    <Pages.SpotBids />
                  </SiteSection>
                </PrivateRoute>

                {!isPaymentsUser && (
                  <Route path={`(${AppRoute.ACCOUNTS_RECEIVABLE}|${AppRoute.CLAIMS})`}>
                    <SiteSection name="Accounts Receivable">
                      <PrivateRoute path={AppRoute.ACCOUNTS_RECEIVABLE} component={Pages.AccountsReceivable} />
                      <PrivateRoute path={AppRoute.CLAIMS} component={Pages.Claims} />
                    </SiteSection>
                  </Route>
                )}

                {!isPaymentsUser && (
                  <Route path={`(${AppRoute.PAYMENTS}`}>
                    <PrivateRoute path={AppRoute.PAYMENTS}>
                      <Redirect to={AppRoute.ACCOUNTS_RECEIVABLE} />
                    </PrivateRoute>
                  </Route>
                )}

                {/* if user has enable payments user flag true then redirect accounts receivable to payments*/}
                {isPaymentsUser && (
                  <Route path={`(${AppRoute.ACCOUNTS_RECEIVABLE}`}>
                    <PrivateRoute path={AppRoute.ACCOUNTS_RECEIVABLE}>
                      <Redirect to={AppRoute.PAYMENTS} />
                    </PrivateRoute>
                  </Route>
                )}

                {isPaymentsUser && (
                  <Route path={`(${AppRoute.PAYMENTS}|${AppRoute.CLAIMS})`}>
                    <SiteSection name="Payments">
                      <PrivateRoute path={AppRoute.PAYMENTS} component={Pages.Payments} />
                      <PrivateRoute path={AppRoute.CLAIMS} component={Pages.Claims} />
                    </SiteSection>
                  </Route>
                )}

                <SiteSection name="Settings">
                  <Route path={AppRoute.SMS_SUBSCRIPTION} component={Pages.SMSSubscription} />
                </SiteSection>

                <PrivateRoute exact path={AppRoute.FINANCIAL_TERMS_AND_CONDITIONS}>
                  <SiteSection name="Financial Terms and Conditions">
                    <Pages.FinancialTermsAndConditions />
                  </SiteSection>
                </PrivateRoute>

                <PrivateRoute path={AppRoute.MANAGE_INVOICES}>
                  <SiteSection name="Manage Invoices">
                    <Switch>
                      <PrivateRoute path={AppRoute.MANAGE_INVOICES} exact>
                        <Pages.ManageInvoices />
                      </PrivateRoute>

                      <PrivateRoute path={AppRoute.CREATE_INVOICE}>
                        <Pages.CreateInvoice />
                      </PrivateRoute>

                      <PrivateRoute path={AppRoute.VIEW_INVOICE} render={({ match: { params } }) => <Pages.ViewInvoice invoiceId={params.id} />} />

                      <PrivateRoute path={AppRoute.EDIT_INVOICE} render={({ match: { params } }) => <Pages.EditInvoice invoiceId={params.id} />} />
                    </Switch>
                  </SiteSection>
                </PrivateRoute>

                <Route path={FIND_LOADS_ROUTES}>
                  <SiteSection name="Available Loads">
                    <PrivateRoute
                      path={AppRoute.FIND_LOADS}
                      render={({
                        match: {
                          params: { searchType },
                        },
                      }) => <RequiresInsurance>{renderFindLoadsComponent(searchType)}</RequiresInsurance>}
                    />

                    <Switch>
                      {' '}
                      {/* BOOK IT NOW */}
                      <PrivateRoute path={AppRoute.BIN_DEEP_LINK} render={({ match: { params } }) => <Redirect to={AppRoute.FIND_LOADS_BASE} />} />
                      <PrivateRoute path={AppRoute.BIN}>
                        <Redirect to={AppRoute.FIND_LOADS_BASE} />
                      </PrivateRoute>
                    </Switch>

                    <PrivateRoute path={AppRoute.PREFERRED_LANES}>
                      <RequiresInsurance>
                        <Pages.PreferredLanes />
                      </RequiresInsurance>
                    </PrivateRoute>
                  </SiteSection>
                </Route>

                <PrivateRoute path={AppRoute.DASHBOARD}><Pages.DashboardV2 /></PrivateRoute>
                <Switch>
                  <PrivateRoute
                    path={AppRoute.CARRIER_ADVANTAGE_PROGRAM_DEEP_LINK}
                    render={({ match: { params } }) => <Pages.CarrierAdvantageProgram faqId={params.id} />}
                  />
                  <PrivateRoute path={AppRoute.CARRIER_ADVANTAGE_PROGRAM}>
                    <Pages.CarrierAdvantageProgram />
                  </PrivateRoute>
                </Switch>
                <Route path={AppRoute.CARRIER_ADVANTAGE_FAQ}>
                  <SiteSection name="FAQ">
                    <Switch>
                      <PrivateRoute
                        path={AppRoute.CARRIER_ADVANTAGE_FAQ_DEEP_LINK}
                        render={({ match: { params } }) => <Pages.CarrierAdvantageFaq faqId={params.id} />}
                      />
                      <PrivateRoute path={AppRoute.CARRIER_ADVANTAGE_FAQ} component={Pages.CarrierAdvantageFaq} />
                    </Switch>
                  </SiteSection>
                </Route>
                <Route path={AppRoute.CAP_SCORE_DETAILS}>
                  <SiteSection name="Cap Score Details">
                    <Switch>
                      <PrivateRoute
                        path={AppRoute.CAP_SCORE_DETAILS_DEEP_LINK}
                        render={({ match: { params } }) =>
                          <Pages.CapLoadDetailsV2 timePeriod={params.id} />
                        }
                      />
                      <PrivateRoute path={AppRoute.CAP_SCORE_DETAILS} component={Pages.CapLoadDetailsV2} />
                    </Switch>
                  </SiteSection>
                </Route>
                <Route path={FAQ}>
                  <SiteSection name="Frequently Asked">
                    <Switch>
                      <Route path={AppRoute.OKTA_FAQ} component={Pages.OktaFaq} exact />
                    </Switch>
                  </SiteSection>
                </Route>
                {isOktaEnabled && (
                  <PrivateRoute path={AppRoute.VERIFY}>
                    <SiteSection name="Verify Preferred Email">
                      <Switch>
                        <Route path={AppRoute.VERIFY} component={Pages.SetOktaPreferredEmail} exact />
                      </Switch>
                    </SiteSection>
                  </PrivateRoute>
                )}
              </PageWrapper>
            </Route>
            {/*  Restricted/ authorization based access enforcement */}
      //#region
            <PrivateRoute
              path={AppRoute.FIND_LOAD_DETAILS}
              render={({ match: { params } }) => (
                <SiteSection name="Find Loads">
                  <RequiresInsurance>
                    <DetailPopupWrapper>
                      <Pages.AvailableLoadDetails loadId={params.id} />
                    </DetailPopupWrapper>
                  </RequiresInsurance>
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.SPOT_BID_DETAILS}
              render={({ match: { params } }) => (
                <SiteSection name="Spot Bids">
                  <DetailPopupWrapper>
                    <Pages.SpotBidsDetails bidId={params.id} carrierCode={params.carrierCode} />
                  </DetailPopupWrapper>
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.MY_LOAD_DETAILS}
              render={({ match: { params }, location }) => (
                <SiteSection name="My Loads">
                  <Pages.LoadDetails
                    loadId={params.id}
                    bookType={qs.parse(location.search, { ignoreQueryPrefix: true }).bookType}
                    carrierCode={qs.parse(location.search, { ignoreQueryPrefix: true }).carrierCode}
                    bookSeqNum={null}
                    isGlobalShipment={false}
                  />
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.MY_SHIPMENT_DETAILS}
              render={({ match: { params }, location }) => (
                <SiteSection name="My Shipments">
                  <Pages.LoadDetails
                    loadId={params.id}
                    bookType={qs.parse(location.search, { ignoreQueryPrefix: true }).bookType}
                    carrierCode={qs.parse(location.search, { ignoreQueryPrefix: true }).carrierCode}
                    bookSeqNum={qs.parse(location.search, { ignoreQueryPrefix: true }).bookSeqNum}
                    isGlobalShipment={true}
                  />
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.ACCOUNTS_RECEIVABLE_DETAILS}
              render={({ match: { params } }) => (
                <SiteSection name="Accounts Receivable">
                  <DetailPopupWrapper>
                    <Pages.AccountsReceivableDetails loadId={params.id} />
                  </DetailPopupWrapper>
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.ACCOUNTS_RECEIVABLE_INVOICE_DETAILS}
              render={({ match: { params } }) => (
                <SiteSection name="Accounts Receivable">
                  <DetailPopupWrapper>
                    <Pages.AccountsReceivableInvoiceDetails loadId={params.id} voucherId={params.voucherId} voucherDocId={params.voucherDocId} />
                  </DetailPopupWrapper>
                </SiteSection>
              )}
            />
            <PrivateRoute
              path={AppRoute.ACCOUNTS_RECEIVABLE_CHECK_DETAILS}
              render={({ match: { params }, location }) => (
                <SiteSection name="Accounts Receivable">
                  <DetailPopupWrapper>
                    <Pages.AccountsReceivableCheckDetails
                      checkId={params.id}
                      carrierCode={qs.parse(location.search, { ignoreQueryPrefix: true }).carrierCode}
                    />
                  </DetailPopupWrapper>
                </SiteSection>
              )}
            />
            <PrivateRoute path={AppRoute.DOCK_MANAGER}>
              <SiteSection name="External Links">
                <Pages.DockManager />
              </SiteSection>
            </PrivateRoute>
      //#endregion
            <Route>
              <PageWrapper>
                <PageNotFound />
              </PageWrapper>
            </Route>
          </Switch>
          {!hideFooter() && <Footer />}
          <PageContentCardFeed />
        </LazyBoundary>
      </div>
    </ErrorBoundary>
  );
};
