import { AppRoute } from '@app/routesEnum';
import { MaintenanceWindow } from '@containers/layout/maintenance/maintenance-window.component';
import { Pages } from '@pages/pages';
import { UnauthenticatedRoute } from '@shared/components/route/unauthenticated-route.component';
import Animation from '@pages/login/animation/animation.component';
import { Route } from 'react-router';

export interface ILoginRoutes {
 oktaUIEnabled?: boolean;
}

const LoginRoutes = ({ oktaUIEnabled = false }: ILoginRoutes) => {
 return (
  <>
   <UnauthenticatedRoute path={AppRoute.LOGIN}>
    <MaintenanceWindow />
    <Animation>
     <Route exact path={AppRoute.LOGIN} render={() => <Pages.Login {...{ oktaUIEnabled }} />} />
    </Animation>
   </UnauthenticatedRoute>
   <UnauthenticatedRoute path={AppRoute.LOGIN_OTP}>
    <Route path={AppRoute.LOGIN_OTP} component={Pages.LoginOtpComponent} />
   </UnauthenticatedRoute>
   <Route path={AppRoute.VERIFY_EMAIL} component={Pages.VerifyEmailAddress} />
  </>
 );
};

export default LoginRoutes;
